import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import {WCard} from '../../styles/common';
import CardContent from '@mui/material/CardContent';
import LoginForm from '../../components/LoginForm/LoginForm';
import Layout from '../../components/Layout/Layout';
import {useNavigate} from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    const onLogin = () => {
        setTimeout(() => {
            navigate('/moje-zwierzeta');
        }, 200);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Znajdź lecznicę weterynaryjną w Twojej okolicy - weteo.pl</title>
                <meta name="description" content="Znajdź polecane lecznice weterynaryjne w Twojej okolicy. Sprawdź opinie o placówkach weterynaryjnych w Polsce." />
            </Helmet>
            <Layout>
                <Grid container>
                    <Grid item md={3} sm={0} />

                    <Grid item md={6} sm={12}>

                        <WCard
                            sx={{
                                mt: '20px',
                                mb: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <CardContent>

                                <LoginForm
                                    onLogin={onLogin}
                                />

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default Home;

// styles
const HomeLayout = styled('div')({
    backgroundColor: '#fbfbfb',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});
