import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { FormItemError, H1, H5, InfoSmall, WButton, WCard, WLink, FormGroup, SwitchLabel } from '../../styles/common';
import { CardContent, Grid, Breadcrumbs, Box, TextField, Switch } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PageNavigator from '../../components/PageNavigator/PageNavigator';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../Api/http';
import Loading from '../../components/helpers/Loading';
import { handleErrors } from '../../utils/helpers';
import { setMessage } from '../../store/actions';

const initialUserData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
}

const ProfileSettings = () => {
    const [userData, setUserData] = useState<any>(initialUserData);
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const user = useSelector(((state: any) => state.user));

    useEffect(() => {
        loadProfileData();
    }, []);

    const loadProfileData = () => {
        setIsLoading(true);
        http().get('/profile')
            .then(({ data }) => {
                setUserData(data);
            })
            .catch(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Nie można załadować danych, spróbuj jeszcze raz za chwilę lub zgłoś problem administratorowi.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onFieldChange = (field: string, value: any) => {
        const newUserData: any = { ...userData };
        newUserData[field] = value;
        setUserData(newUserData);
    };

    const onSubmit = (ev: any) => {
        ev.preventDefault();
        setErrors({});

        const dataToSave = { ...userData };
        delete dataToSave.email;

        setIsSaving(true);
        http().patch('/profile', dataToSave)
            .then(() => {
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Profil został zaktualizowany.',
                    type: 'success',
                }));
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/profil-i-ustawienia`}>Profil i ustawienia</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={12} xs={12}>
                        <PageNavigator active="profile-settings" />
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <WCard sx={{ mb: '20px' }}>
                            <CardContent>
                                <H1>Profil i ustawienia</H1>

                                {isLoading && <Loading />}

                                {!isLoading && <Box
                                    component="form"
                                    onSubmit={onSubmit}
                                    sx={{
                                        pt: '20px',
                                    }}
                                >

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('email', ev.target.value)}
                                                disabled={true}
                                                required={true}
                                                fullWidth
                                                label="Adres e-mail"
                                                type="email"
                                                size="small"
                                                value={userData.email || ''}
                                                error={errors.email && errors.email.length > 0}
                                            />
                                            {errors.email && <FormItemError>{errors.email}</FormItemError>}
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <InfoSmall>
                                                Adres e-mail to Twój login, dlatego nie można go zmienić. Jeżeli jednak chcesz go zmienić, prosimy o kontakt.
                                            </InfoSmall>
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('firstName', ev.target.value)}
                                                disabled={isSaving}
                                                required={true}
                                                fullWidth
                                                label="Imię"
                                                size="small"
                                                value={userData.firstName || ''}
                                                error={errors.firstName && errors.firstName.length > 0}
                                            />
                                            {errors.firstName && <FormItemError>{errors.firstName}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('lastName', ev.target.value)}
                                                disabled={isSaving}
                                                fullWidth
                                                label="Nazwisko"
                                                size="small"
                                                value={userData.lastName || ''}
                                                error={errors.lastName && errors.lastName.length > 0}
                                            />
                                            {errors.lastName && <FormItemError>{errors.lastName}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                onChange={(ev) => onFieldChange('phone', ev.target.value)}
                                                disabled={isSaving}
                                                fullWidth
                                                label="Numer telefonu"
                                                size="small"
                                                type="tel"
                                                value={userData.phone || ''}
                                                error={errors.phone && errors.phone.length > 0}
                                            />
                                            {errors.phone && <FormItemError>{errors.phone}</FormItemError>}
                                        </Grid>
                                    </FormGroup>

                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            {isSaving && <Loading />}

                                            {!isSaving && <WButton
                                                disabled={isSaving}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                            >Zapisz zmiany</WButton>}
                                        </Grid>
                                    </Grid>

                                </Box>}

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default ProfileSettings;
