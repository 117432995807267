import React, {useEffect, useState} from 'react';
import {Dict, Pet} from '../../../types/types';
import {Table, TableBody, TableCell, TableRow} from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import {species} from '../../../config/constants';
import {WButton} from '../../../styles/common';
import EditIcon from '@mui/icons-material/Edit';

const PetData = ({ pet, onEdit }: { pet: Pet, onEdit: () => void }) => {
    const [petFields, setPetFields] = useState<any>({ column1: [], column2: []});

    useEffect(() => {
        setPetFields({
            column1: [
                { label: 'Imię', value: pet.name },
                { label: 'Płeć', value: pet.gender === 1 ? 'samiec' : 'samica' },
                { label: 'Data urodzenia', value: pet.birthDate },
                { label: 'Gatunek', value: getSpeciesName(pet.speciesId).name },
                { label: 'Rasa', value: pet.breed },
            ],
            column2: [
                { label: 'Kolor i rodzaj sierści', value: pet.color },
                { label: 'Znaki szczególne', value: pet.specialMarks },
                { label: 'Nr tatuażu / chip', value: pet.number },
                { label: 'Nr paszportu', value: pet.passport },
                { label: 'Komentarz', value: pet.comment },
            ],
        });
    }, [pet]);

    const getSpeciesName = (id: number): Dict => {
        return species.find((item) => item.id === id) || { id: 0, name: '' };
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12} sx={{ width: '100%' }}>
                    <Table>
                        <TableBody>
                        {petFields.column1.map((field: any, key: number) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        {field.label}:
                                    </TableCell>
                                    <TableCellBold>
                                        {field.value}
                                    </TableCellBold>
                                </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ width: '100%' }}>
                    <Table>
                        <TableBody>
                        {petFields.column2.map((field: any, key: number) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        {field.label}:
                                    </TableCell>
                                    <TableCellBold>
                                        {field.value}
                                    </TableCellBold>
                                </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8} />
                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: 'right' }}>
                    <WButton
                        sx={{ margin: '10px' }}
                        onClick={onEdit}
                        variant="contained"
                        size="small"
                        startIcon={<EditIcon />}
                    >Edycja</WButton>
                </Grid>
            </Grid>
        </>
    );
};
export default PetData;

// styles
const TableCellBold = styled(TableCell)({
    fontWeight: 'bold',
});