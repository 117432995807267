import React from 'react';
import { isMobile } from 'react-device-detect';
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from 'react-facebook-login-typed';
import { login, setMessage } from '../../store/actions';
import http from '../../Api/http';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { Colors } from '../../styles/colors';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FacebookLoginButton = (
    { userType, label, vetType, ownerType }:
        { userType: number; label?: string; vetType?: boolean; ownerType?: boolean }
) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const componentClicked = () => {
        // console.log('clicked');
        // hide button, add some loader
    };

    const responseFacebook = (fb: any) => {
        if (!fb || !fb.accessToken || !fb.name) {
            return dispatch(setMessage({
                isOpen: true,
                content: 'Wystąpił błąd podczas logowania za pomocą konta Facebook. Spróbuj ponownie za chwilę.',
                type: 'error',
            }));
        }

        const name = fb.name.split(' ');
        const fbUser = {
            fbProfileId: fb.id,
            email: fb.email,
            firstName: name.slice(0, name.length-1).join(' '),
            lastName: name[name.length-1],
            pictureUrl: fb.picture.data.url || null,
            token: fb.accessToken,
            type: userType,
            vetType,
            ownerType,
        };

        http().post('/auth/fb-register', { ...fbUser })
            .then(({ data }) => {
                if (data && data.tokenValid && data.token) {
                    dispatch(login(data.token));
                    navigate('/'); // TODO: change to get redirect link + add snackbar
                }
            })
            .catch((error) => {
                return dispatch(setMessage({
                    isOpen: true,
                    content: 'Wystąpił błąd podczas autoryzazji Twojego konta Facebook. Spróbuj ponownie za chwilę.',
                    type: 'error',
                }));
            });
    };

    return (
        <FacebookLogin
            appId="969505290660737"
            autoLoad={false}
            disableMobileRedirect={isMobile}
            fields="name,email,picture"
            onClick={componentClicked}
            callback={responseFacebook}
            render={({ onClick, isDisabled, isProcessing, isSdkLoaded }) => (
                <FbButton
                    disabled={!isSdkLoaded}
                    onClick={onClick}
                    sx={{ mb: 2 }}
                    fullWidth
                    variant="contained"
                    startIcon={<FacebookIcon />}
                >{label || 'Zaloguj się poprzez Facebook'}</FbButton>
            )}
        />
    );
};
export default FacebookLoginButton;

// styles
const FbButton = styled(Button)({
    backgroundColor: Colors.fbBlue,
    textTransform: 'none',
    boxShadow: 'none',
    ':hover': {
        boxShadow: 'none',
    },
});
