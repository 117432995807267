import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {FormGroup, FormItemError} from '../../styles/common';
import {Dict} from '../../types/types';

const SelectInput = (
    { label, options, value, disabled = false, error = '', required = false, onChange }:
        { label: string, options: Dict[], value: number, disabled?: boolean, error?: string, required?: boolean, onChange: (ev: any) => void }
) => {
    return (
        <div className="Select">
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    fullWidth
                    label={label}
                    size="small"
                    value={value || ''}
                    error={error.length > 0}
                >
                    {options.map((opt: any, key: number) => {
                        return (
                            <MenuItem value={opt.id} key={key}>
                                {opt.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {error && <FormItemError>{error}</FormItemError>}
        </div>
    );
};
export default SelectInput;