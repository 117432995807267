import React from 'react';
import { CardActions, CardContent, CardMedia, IconButton } from '@mui/material';
import { H3, WCard } from '../../../styles/common';
import ShareIcon from '@mui/icons-material/Share';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from 'react-router-dom';

const PetTile = ({ pet }: { pet: any }) => {
    const navigate = useNavigate();

    return (
        <WCard
            onClick={() => navigate(`/moje-zwierzeta/podglad/${pet.id}`)}
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', cursor: 'pointer' }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={`/img/icons/ico_pet_type_${pet.speciesId}.svg`} height={24} />
                <H3>{pet.name}</H3>
            </CardContent>
            <CardMedia
                component="img"
                height="100"
                image="/img/img_placeholder.png"
                alt="Paella dish"
            />
            <CardActions>
                {/*<IconButton>*/}
                {/*    <ShareIcon />*/}
                {/*</IconButton>*/}
                {/*<IconButton>*/}
                {/*    <MenuBookIcon />*/}
                {/*</IconButton>*/}
                <IconButton>
                    <EditIcon />
                </IconButton>
            </CardActions>
        </WCard>
    );
};
export default PetTile;
