import React, { useState} from 'react';
import {Box, Grid, TextField} from '@mui/material';
import { H1, WButton, FormGroup } from '../../../styles/common';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import { handleErrors } from '../../../utils/helpers';
import { species, genders } from '../../../config/constants';
import Loading from '../../../components/helpers/Loading';
import {initialPet, Pet} from '../../../types/types';
import PetsIcon from '@mui/icons-material/Pets';
import TextInput from '../../../components/Forms/TextInput';
import SelectInput from '../../../components/Forms/SelectInput';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import {useNavigate} from 'react-router-dom';

const AddPetForm = ({ speciesId }: { speciesId?: string }) => {
    const isAuth = useSelector((state: any) => state.isAuth);
    const [pet, setPet] = useState<Pet>({
        ...initialPet,
        speciesId: parseInt(speciesId || '1', 10),
    });
    const [errors, setErrors] = useState<any>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onPetChange = (field: string, value: any) => {
        const newPet: any = { ...pet };
        newPet[field] = value;
        setPet(newPet);
    };

    const onPetSubmit = (event: any) => {
        event.preventDefault();
        setIsSaving(true);
        setErrors({});

        const petData = {
            ...pet,
        };

        http().post('/pet-health-book/pets', petData)
            .then(() => {
                setPet(initialPet);
                navigate('/moje-zwierzeta');
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <>
            <H1>{'Dodaj zwierzę'}</H1>
            <Box
                component="form"
                onSubmit={onPetSubmit}
                sx={{
                    pt: '20px',
                }}
            >
                <FormGroup>
                    <TextInput
                        label="Imię zwierzęcia"
                        disabled={isSaving}
                        required={true}
                        error={errors.name || ''}
                        onChange={(ev) => onPetChange('name', ev.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <SelectInput
                        label="Płeć"
                        options={genders}
                        disabled={isSaving}
                        required={true}
                        value={pet.gender}
                        error={errors.gender}
                        onChange={(ev) => onPetChange('gender', ev.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <SelectInput
                        label="Gatunek"
                        options={species}
                        disabled={isSaving}
                        required={true}
                        value={pet.speciesId}
                        error={errors.speciesId}
                        onChange={(ev) => onPetChange('speciesId', ev.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <TextInput
                        label="Rasa"
                        disabled={isSaving}
                        required={false}
                        error={errors.breed || ''}
                        onChange={(ev) => onPetChange('breed', ev.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                        <DatePicker
                            onChange={(ev) => onPetChange('birthDate', ev)}
                            value={pet.birthDate}
                            renderInput={(params) => <TextField {...params} fullWidth size="small" label="Data urodzenia" />}
                        />
                    </LocalizationProvider>
                </FormGroup>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={8}>
                        <WButton
                            disabled={isSaving}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            startIcon={<PetsIcon />}
                        >Dodaj zwierzę</WButton>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WButton
                            onClick={() => navigate('/moje-zwierzeta')}
                            disabled={isSaving}
                            fullWidth
                            variant="outlined"
                            size="large"
                        >Anuluj</WButton>
                    </Grid>
                    {isSaving && <Grid item md={12} sm={12} xs={12}>
                        <Loading />
                    </Grid>}
                </Grid>

            </Box>
        </>
    );
};
export default AddPetForm;
