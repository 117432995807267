import React, { useEffect, useState} from 'react';
import Layout from '../../components/Layout/Layout';
import { WButton, WLink } from '../../styles/common';
import {Grid, Breadcrumbs, Menu, MenuItem } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import http from '../../Api/http';
import PetTile from './components/PetTile';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import { species } from '../../config/constants';

const MyPets = () => {
    const [pets, setPets] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        http().get('/pet-health-book/pets')
            .then(({ data }) => {
                setPets(data);
            });
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-zwierzeta`}>Moje zwierzęta</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: '20px' }}>
                    <ButtonsContainer item>
                        <WButton
                            onClick={() => navigate('/moje-zwierzeta/dodaj/1')}
                            variant="contained"
                        >Dodaj psa</WButton>

                        <WButton
                            onClick={() => navigate('/moje-zwierzeta/dodaj/2')}
                            variant="contained"
                        >Dodaj kota</WButton>

                        <WButton onClick={handleClick} variant="contained">Dodaj inne zwierzę</WButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {Array.isArray(species) && species.filter((spec) => spec.id > 2).map((spec, k) => {
                                return <MenuItem key={k} onClick={() => navigate(`/moje-zwierzeta/dodaj/${spec.id}`)}>{spec.name}</MenuItem>
                            })}
                        </Menu>

                    </ButtonsContainer>
                </Grid>

                <Grid container spacing={2}>
                    {pets.map((pet: any, key: number) => {
                        return (
                            <Grid
                                item
                                key={key}
                                md={3}
                                sm={6}
                                xs={12}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <PetTile pet={pet} />
                            </Grid>
                        );
                    })}
                </Grid>

            </Layout>
        </HelmetProvider>
    );
};
export default MyPets;

// styles
const ButtonsContainer = styled(Grid)({
    button: {
        marginRight: 10,
    },
});
