import type { Dict } from '../types/types';

export const genders: Dict[] = [
    { id: 1, name: 'samiec' },
    { id: 2, name: 'samica' },
];

export const species: Dict[] = [
    { id: 1, name: 'pies' },
    { id: 2, name: 'kot' },
    { id: 3, name: 'chomik' },
    { id: 4, name: 'świnka morska' },
    { id: 5, name: 'mysz' },
    { id: 6, name: 'królik' },
    { id: 7, name: 'kanarek' },
    { id: 8, name: 'papuga' },
    { id: 9, name: 'żółw' },
    { id: 10, name: 'jaszczurka' },
    { id: 11, name: 'norka' },
    { id: 12, name: 'szynszyla' },
    { id: 13, name: 'myszoskoczek' },
    { id: 14, name: 'szczur' },
    { id: 15, name: 'tchórzofretka' },
    { id: 16, name: 'wąż' },
    { id: 17, name: 'pająk' },
];

export const provinces: Dict[] = [
    { id: 1, name: 'dolnośląskie', slug: 'dolnoslaskie' },
    { id: 2, name: 'kujawsko-pomorskie', slug: 'kujawsko-pomorskie' },
    { id: 3, name: 'lubelskie', slug: 'lubelskie' },
    { id: 4, name: 'lubuskie', slug: 'lubuskie' },
    { id: 5, name: 'łódzkie', slug: 'lodzkie' },
    { id: 6, name: 'małopolskie', slug: 'malopolskie' },
    { id: 7, name: 'mazowieckie', slug: 'mazowieckie' },
    { id: 8, name: 'opolskie', slug: 'opolskie' },
    { id: 9, name: 'podkarpackie', slug: 'podkarpackie' },
    { id: 10, name: 'podlaskie', slug: 'podlaskie' },
    { id: 11, name: 'pomorskie', slug: 'pomorskie' },
    { id: 12, name: 'śląskie', slug: 'slaskie' },
    { id: 13, name: 'świętokrzyskie', slug: 'swietokrzyskie' },
    { id: 14, name: 'warmińsko-mazurskie', slug: 'warminsko-mazurskie' },
    { id: 15, name: 'wielkopolskie', slug: 'wielkopolskie' },
    { id: 16, name: 'zachodniopomorskie', slug: 'zachodniopomorskie' },
];

export const daysNames: Dict[] = [
    { id: 1, name: 'poniedziałek' },
    { id: 2, name: 'wtorek' },
    { id: 3, name: 'środa' },
    { id: 4, name: 'czwartek' },
    { id: 5, name: 'piątek' },
    { id: 6, name: 'sobota' },
    { id: 7, name: 'niedziela' },
];
