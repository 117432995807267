import React, {useEffect} from 'react';
import Layout from '../../components/Layout/Layout';
import {WCard, WLink} from '../../styles/common';
import { Grid, Breadcrumbs } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddPetForm from './components/AddPetForm';
import CardContent from '@mui/material/CardContent';
import {useParams} from 'react-router-dom';

const PetAdd = () => {
    const params = useParams();

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-zwierzeta`}>Moje zwierzęta</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} sm={2} xs={0}></Grid>
                    <Grid item md={6} sm={8} xs={12}>
                        <WCard>
                            <CardContent>
                                <AddPetForm speciesId={params.speciesId} />
                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default PetAdd;
