import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layout/Layout';
import {WButton, WCard, WLink} from '../../styles/common';
import {Grid, Breadcrumbs, CardMedia, Tabs, Tab, Table, TableRow, TableCell} from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CardContent from '@mui/material/CardContent';
import {useParams} from 'react-router-dom';
import http from '../../Api/http';
import Box from '@mui/material/Box';
import EditPetForm from './components/EditPetForm';
import {initialPet, Pet} from '../../types/types';
import styled from '@emotion/styled';
import PetData from './components/PetData';

const PetView = () => {
    const [pet, setPet] = useState<Pet>(initialPet);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const params = useParams();

    useEffect(() => {
        loadPetData();
    }, []);

    const loadPetData = () => {
        http().get(`/pet-health-book/pets/${params.petId}`)
            .then(({ data }) => {
                setPet(data);
            })
            .catch((error) => {

            });
    };

    const onPetUpdated = () => {
        setIsEditMode(false);
        loadPetData();
    };

    return (
        <HelmetProvider>
            <Layout>
                <Grid container sx={{ pt: '20px', mb: '20px' }}>
                    <Grid item xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                            <WLink variant="small" to={`/`}>weteo.pl</WLink>
                            <WLink variant="small" to={`/moje-zwierzeta`}>Moje zwierzęta</WLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <WCard>

                            <img src={`/img/icons/ico_pet_type_${pet.speciesId}.svg`} height={300} />


                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    // image="/img/img_placeholder.png"*/}
                            {/*    image="/img/cat.jpg"*/}
                            {/*    alt="Paella dish"*/}
                            {/*    height="100%"*/}
                            {/*/>*/}
                        </WCard>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} >
                        <WCard>
                            {!isEditMode && <PetData pet={pet} onEdit={() => setIsEditMode(true)} />}
                            {isEditMode && pet.id && <EditPetForm petData={pet} onClose={onPetUpdated} />}
                        </WCard>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <WCard>
                            <Tabs
                                value={selectedTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Szczepienia" onClick={() => setSelectedTab(0)} />
                                <Tab label="Odbrobaczanie" onClick={() => setSelectedTab(1)} />
                                <Tab label="Wizyty" onClick={() => setSelectedTab(2)} />
                                <Tab label="Uzębienie" onClick={() => setSelectedTab(3)} />
                                <Tab label="Zabiegi" onClick={() => setSelectedTab(4)} />
                                <Tab label="Kontola płodności" onClick={() => setSelectedTab(5)} />
                                <Tab label="Pomiary" onClick={() => setSelectedTab(6)} />


                            </Tabs>

                            {selectedTab === 0 && <CardContent>
                                Lista wizyt
                            </CardContent>}

                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
};
export default PetView;

// styles

