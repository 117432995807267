import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../store/actions';
import { WButton, WLink, WCard, H1, FormItemError } from '../../styles/common';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Layout from '../../components/Layout/Layout';
import http from '../../Api/http';
import CardContent from '@mui/material/CardContent';
import styled from '@emotion/styled';
import { Colors } from '../../styles/colors';
import { Navigate, useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RemoveIcon from '@mui/icons-material/Remove';
import FacebookLoginButton from '../../components/FacebookLoginButton/FacebookLoginButton';
import { handleErrors } from '../../utils/helpers';
import config from '../../config/config';

const AuthRegister = () => {
    const [userType, setUserType] = useState<number>(10);
    const [errors, setErrors] = useState<any>({});
    const [vetType, setVetType] = useState<boolean>(false);
    const [ownerType, setOwnerType] = useState<boolean>(false);
    const [registerCompleted, setRegisterCompleted] = useState<boolean>(false);
    const [registerEmail, setRegisterEmail] = useState<boolean>(false);
    const emailInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();
    const firstNameInputRef = useRef<HTMLInputElement>();
    const lastNameInputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        if (params && params.type && params.type === 'weterynarz') {
            setUserType(20);
            setVetType(true);
        } else {
            setUserType(10);
        }
    }, [params, params.type]);

    const isAuth = useSelector((state: any) => state.isAuth);
    if (isAuth) {
        return <Navigate to="/" />;
    }

    const handleVetOnchange = (event: any) => {
        setVetType(event.target.checked);
    };

    const handleOwnerOnchange = (event: any) => {
        setOwnerType(event.target.checked);
    };

    const registerHandler = (event: any) => {
        event.preventDefault();
        setErrors({});

        const email = emailInputRef?.current?.value;
        const password = passwordInputRef?.current?.value;
        const firstName = firstNameInputRef?.current?.value;
        const lastName = lastNameInputRef?.current?.value;

        http()
            .post(
                '/auth/register',
                { email, password, firstName, lastName, vetType, ownerType, type: userType }
            )
            .then(({ data }) => {
                setRegisterCompleted(true);
                // dispatch(setMessage({
                //     isOpen: true,
                //     content: 'Rejestracja przebiegła pomyślnie. Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym.',
                //     type: 'success',
                // }));
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw dane w formularzu i spróbuj ponownie',
                    type: 'error',
                }));
            });
    };

    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{'Załóż konto - weteo.pl'}</title>
                    <meta name="description" content="Strona rejestracji użytkownika serwisu weteo.pl" />
                    <link rel="canonical" href={`${config.baseUrl}/rejestracja`} />
                </Helmet>

                <Grid container>
                    <Grid item md={3} sm={0} />

                    <Grid item md={6} sm={12}>

                        {registerCompleted && <WCard
                            sx={{
                                mt: '20px',
                                mb: '20px',
                            }}
                        >
                            <CardContent>
                                <p>
                                    Rejestracja przebiegła pomyślnie.
                                </p>
                                <p>
                                    Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym. Prosimy o kliknięcie w link, aby móc się zalogować i w pełni korzystać ze swojego konta.
                                </p>
                                <p>
                                    Jeżeli wiadomość nie dotarła, poczekaj kilka minut lub sprawdź zakładkę SPAM. W przeciwnym razie skontaktuj się z nami poprzez zakładkę <WLink to={'/kontakt'}>Kontakt</WLink>.
                                </p>
                                <p>
                                    Pozdrawiamy,
                                    <br />
                                    weteo.pl
                                </p>
                                <Grid container>
                                    <Grid item xs />
                                    <Grid item>
                                        <WLink to="/logowanie">
                                            Masz już konto? Zaloguj się!
                                        </WLink>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </WCard>}


                        {!registerCompleted && <WCard
                            sx={{
                                mt: '20px',
                                mb: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <CardContent>
                                <H1>
                                    Załóż konto w serwisie weteo.pl
                                </H1>
                                <Box
                                    component="form"
                                    onSubmit={(event: any) => registerHandler(event)}
                                    sx={{ mt: 1 }}
                                >
                                    <Grid container>
                                        <Grid item md={12}>
                                            <FormGroupStyled>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox defaultChecked />
                                                    }
                                                    label={'Jestem właścicielem zwierzęcia'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={vetType}
                                                            onChange={handleVetOnchange}
                                                        />
                                                    }
                                                    label={'Jestem lekarzem weterynarii'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={ownerType}
                                                            onChange={handleOwnerOnchange}
                                                        />
                                                    }
                                                    label={'Jestem właścicielem placówki'}
                                                />
                                            </FormGroupStyled>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item>
                                            <FormLabel>
                                                Rejestrując się w serwisie weteo.pl akceptujesz <WLink to={'/regulamin'}>Regulamin</WLink>.
                                            </FormLabel>
                                        </Grid>
                                    </Grid>

                                    {!registerEmail && <Box sx={{ pt: '10px', mb: '20px' }}>
                                        <WButton
                                            fullWidth
                                            variant="outlined"
                                            onClick={() => setRegisterEmail(true)}
                                        >Załóż konto używając adresu e-mail</WButton>
                                    </Box>}

                                    {registerEmail && <Box>
                                        <Grid container>
                                            <Grid item md={12}>
                                                <TextField
                                                    margin="normal"
                                                    inputRef={emailInputRef}
                                                    required
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    label="Adres e-mail"
                                                    name="email"
                                                    type="email"
                                                    autoFocus
                                                    size="small"
                                                    error={errors.email && errors.email.length > 0}
                                                />
                                                {errors.email && <FormItemError>{errors.email}</FormItemError>}

                                                <TextField
                                                    margin="normal"
                                                    inputRef={passwordInputRef}
                                                    required
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    name="password"
                                                    label="Hasło"
                                                    type="password"
                                                    size="small"
                                                />

                                                <TextField
                                                    margin="normal"
                                                    inputRef={firstNameInputRef}
                                                    required
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    name="firstName"
                                                    label="Imię"
                                                    type="text"
                                                    size="small"
                                                />

                                                <TextField
                                                    margin="normal"
                                                    inputRef={lastNameInputRef}
                                                    fullWidth
                                                    required={vetType || ownerType}
                                                    autoComplete={'off'}
                                                    name="lastName"
                                                    label="Nazwisko"
                                                    type="text"
                                                    size="small"
                                                />

                                                <WButton
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    Załóż konto
                                                </WButton>
                                            </Grid>
                                        </Grid>
                                    </Box>}

                                    {!registerEmail && <>
                                        <Box
                                            sx={{
                                                margin: '0 auto 15px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <RemoveIcon/> lub <RemoveIcon />
                                        </Box>

                                        <FacebookLoginButton
                                            userType={userType}
                                            vetType={vetType}
                                            ownerType={ownerType}
                                            label="Zarejestruj się używając Facebooka"
                                        />
                                    </>}

                                    <Grid container>
                                        <Grid item xs />
                                        <Grid item>
                                            <WLink to="/logowanie">
                                                Masz już konto? Zaloguj się!
                                            </WLink>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </WCard>}
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
}
export default AuthRegister;

// styles
const FormGroupStyled = styled(FormGroup)({
    '.Mui-checked': {
        color: Colors.vetFormGreen,
    },
});
