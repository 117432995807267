import React, { useEffect } from 'react';
import Home from './pages/Home/Home';
import AuthLogin from './pages/AuthLogin/AuthLogin';
import AuthRegister from './pages/AuthRegister/AuthRegister';
import AuthConfirmEmail from './pages/AuthRegister/AuthConfirmEmail';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './store/actions';
import CssBaseline from '@mui/material/CssBaseline';
import InfoMessage from './components/InfoMessage/InfoMessage.';
import ScrollToTop from './components/helpers/ScrollToTop';
import ProfileSettings from './pages/Profile/ProfileSettings';
import NotFound from './pages/NotFound/NotFound';
import MyPets from './pages/MyPets/MyPets';
import PetAdd from './pages/MyPets/PetAdd';
import PetView from './pages/MyPets/PetView';

const App = () => {
    return (
        <AuthProvider>
            <>
                <CssBaseline />
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/">
                            <Route index element={<Home/>} />
                            <Route path="logowanie">
                                <Route path=":type" element={<AuthLogin/>}/>
                                <Route index element={<AuthLogin/>}/>
                            </Route>
                            <Route path="rejestracja">
                                <Route path="potwierdz-email/:userId/:hash" element={<AuthConfirmEmail/>}/>
                                <Route path=":type" element={<AuthRegister/>}/>
                                <Route index element={<AuthRegister/>}/>
                            </Route>

                            {/* authenticated routes */}
                            <Route path="profil-i-ustawienia">
                                <Route index element={<RequireAuth><ProfileSettings /></RequireAuth>} />
                            </Route>

                            <Route path="moje-zwierzeta">
                                <Route path="dodaj/:speciesId" element={<RequireAuth><PetAdd /></RequireAuth>} />
                                {/*<Route path="edytuj/:petId" element={<RequireAuth><PetView /></RequireAuth>} />*/}
                                <Route path="podglad/:petId" element={<RequireAuth><PetView /></RequireAuth>} />
                                <Route index element={<RequireAuth><MyPets /></RequireAuth>} />
                            </Route>

                            <Route path="404" element={<NotFound />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                <InfoMessage />
            </>
        </AuthProvider>
    );
}
export default App;

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch();
    const currentCalled = useSelector((state: any) => state.currentCalled);
    useEffect(() => {
        const token = localStorage.getItem('wp-token') || '';
        dispatch(login(token));
    }, [dispatch]);
    return currentCalled ? children : <></>;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const isAuth = useSelector((state: any) => state.isAuth);
    const location = useLocation();
    if (!isAuth) {
        return <Navigate to="/logowanie" state={{ from: location }} />;
    }
    return children;
};
