import React, { useState} from 'react';
import {Box, Grid, TextField} from '@mui/material';
import { H1, WButton, FormGroup } from '../../../styles/common';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../Api/http';
import { setMessage } from '../../../store/actions';
import { handleErrors } from '../../../utils/helpers';
import { species, genders } from '../../../config/constants';
import Loading from '../../../components/helpers/Loading';
import { Pet } from '../../../types/types';
import PetsIcon from '@mui/icons-material/Pets';
import TextInput from '../../../components/Forms/TextInput';
import SelectInput from '../../../components/Forms/SelectInput';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import {useNavigate} from 'react-router-dom';
import CardContent from '@mui/material/CardContent';

const EditPetForm = ({ petData, onClose }: { petData: Pet, onClose: () => void }) => {
    const isAuth = useSelector((state: any) => state.isAuth);
    const [pet, setPet] = useState<Pet>(petData);
    const [errors, setErrors] = useState<any>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onPetChange = (field: string, value: any) => {
        const newPet: any = { ...pet };
        newPet[field] = value;
        setPet(newPet);
    };

    const onPetSubmit = (event: any) => {
        event.preventDefault();
        setIsSaving(true);
        setErrors({});

        const petData = {
            ...pet,
        };

        http().patch(`/pet-health-book/pets/${pet.id}`, petData)
            .then(() => {
                onClose();
            })
            .catch((errors) => {
                handleErrors(errors, (errorsObj: any) => setErrors(errorsObj));
                dispatch(setMessage({
                    isOpen: true,
                    content: 'Popraw błędy w formularzu i spróbuj ponownie.',
                    type: 'error',
                }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const onCancel = () => {
        onClose();
    };

    return (
        <CardContent>
            <Box
                component="form"
                onSubmit={onPetSubmit}
                sx={{
                    pt: '20px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <FormGroup>
                            <TextInput
                                label="Imię zwierzęcia"
                                disabled={isSaving}
                                required={true}
                                value={pet.name}
                                error={errors.name || ''}
                                onChange={(ev) => onPetChange('name', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <SelectInput
                                label="Płeć"
                                options={genders}
                                disabled={isSaving}
                                required={true}
                                value={pet.gender}
                                error={errors.gender}
                                onChange={(ev) => onPetChange('gender', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                                <DatePicker
                                    onChange={(ev) => onPetChange('birthDate', ev)}
                                    value={pet.birthDate}
                                    renderInput={(params) => <TextField {...params} fullWidth size="small" label="Data urodzenia" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>

                        <FormGroup>
                            <SelectInput
                                label="Gatunek"
                                options={species}
                                disabled={isSaving}
                                required={true}
                                value={pet.speciesId}
                                error={errors.speciesId}
                                onChange={(ev) => onPetChange('speciesId', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput
                                label="Rasa"
                                disabled={isSaving}
                                required={false}
                                value={pet.breed}
                                error={errors.breed || ''}
                                onChange={(ev) => onPetChange('breed', ev.target.value)}
                            />
                        </FormGroup>

                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>

                        <FormGroup>
                            <TextInput
                                label="Kolor i rodzaj sierści"
                                disabled={isSaving}
                                value={pet.color}
                                error={errors.color || ''}
                                onChange={(ev) => onPetChange('color', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput
                                label="Znaki szczególne"
                                disabled={isSaving}
                                value={pet.specialMarks}
                                error={errors.specialMarks || ''}
                                onChange={(ev) => onPetChange('specialMarks', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput
                                label="Nr tatuażu / chip"
                                disabled={isSaving}
                                value={pet.number}
                                error={errors.number || ''}
                                onChange={(ev) => onPetChange('number', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput
                                label="Nr paszportu"
                                disabled={isSaving}
                                value={pet.passport}
                                error={errors.passport || ''}
                                onChange={(ev) => onPetChange('passport', ev.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput
                                label="Komentarz"
                                disabled={isSaving}
                                value={pet.comment}
                                error={errors.comment || ''}
                                onChange={(ev) => onPetChange('comment', ev.target.value)}
                            />
                        </FormGroup>

                    </Grid>
                </Grid>




                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={8}>
                        <WButton
                            disabled={isSaving}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                        >Zapisz zmiany</WButton>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WButton
                            onClick={onCancel}
                            disabled={isSaving}
                            fullWidth
                            variant="outlined"
                            size="large"
                        >Anuluj</WButton>
                    </Grid>
                    {isSaving && <Grid item md={12} sm={12} xs={12}>
                        <Loading />
                    </Grid>}
                </Grid>

            </Box>
        </CardContent>
    );
};
export default EditPetForm;
