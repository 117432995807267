import React from 'react';
import {TextField} from '@mui/material';
import { FormItemError} from '../../styles/common';

const TextInput = (
    { label, value, disabled = false, error = '', required = false, onChange }:
        { label: string, value?: string, disabled?: boolean, error?: string, required?: boolean, onChange: (ev: any) => void }
) => {
    return (
        <div className="TextInput">
            <TextField
                onChange={onChange}
                required={required}
                disabled={disabled}
                defaultValue={value}
                fullWidth
                label={label}
                size="small"
                error={error.length > 0}
            />
            {error && <FormItemError>{error}</FormItemError>}
        </div>
    );
};
export default TextInput;