import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from '../../components/Layout/Layout';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const NotFound = () => {
    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>Nie znaleziono strony - weteo.pl</title>
                    <meta name="robots" content="noindex,nofollow" />
                </Helmet>
                <NotFoundContent>
                    Błąd 404
                    <br />
                    Nie znaleziono strony
                    <br />
                    {/*<BackLinkStyled to="/"><KeyboardBackspaceIcon /> <span>Wróć na stronę główną</span></BackLinkStyled>*/}
                </NotFoundContent>
            </Layout>
        </HelmetProvider>
    );
};
export default NotFound;

//styles
const NotFoundContent = styled(Box)({
    textAlign: 'center',
    padding: '40px 0',
    fontSize: 24,
    fontWeight: 'bold',
});
