export type Dict = {
    id: number;
    name: string;
    slug?: string;
};

export type Pet = {
    id?: string;
    name: string;
    speciesId: number;
    gender: number;
    breed: string;
    birthDate: string;
    number?: string;
    passport?: string;
    color?: string;
    specialMarks?: string;
    comment?: string;
};

export const initialPet: Pet = {
    name: '',
    speciesId: 1,
    gender: 1,
    breed: '',
    birthDate: '',
};