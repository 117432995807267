import React from 'react';
import { WCard } from '../../styles/common';
import Grid from '@mui/material/Grid';
import Layout from '../../components/Layout/Layout';
import CardContent from '@mui/material/CardContent';
import LoginForm from '../../components/LoginForm/LoginForm';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import config from '../../config/config';

const AuthLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = useSelector((state: any) => state.isAuth);
    const user = useSelector((state: any) => state.user);
    if (isAuth) {
        return <Navigate to="/" />;
    }

    const from = location.state?.from?.pathname || '/';
    const onLogin = () => {
        setTimeout(() => {
            navigate(from);
        }, 200);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{'Logowanie - weteo.pl'}</title>
                <meta name="description" content="Strona logowania do serwisu weteo.pl" />
                <link rel="canonical" href={`${config.baseUrl}/logowanie`} />
            </Helmet>
            <Layout>
                <Grid container>
                    <Grid item md={3} sm={0} />

                    <Grid item md={6} sm={12}>

                        <WCard
                            sx={{
                                mt: '20px',
                                mb: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <CardContent>

                                <LoginForm
                                    onLogin={onLogin}
                                />

                            </CardContent>
                        </WCard>
                    </Grid>
                </Grid>
            </Layout>
        </HelmetProvider>
    );
}
export default AuthLogin;
